import logo from './logo.png'
import Pusher from './Pusher'
import Link from './Link'

function App() {
  return (
    <div>
      <link rel="stylesheet" href="https://cdnjs.cloudflare.com/ajax/libs/github-fork-ribbon-css/0.2.3/gh-fork-ribbon.min.css" />
      <a class="github-fork-ribbon" href="https://github.com/BlackOrderMod/BlackOrder" data-ribbon="Fork me on GitHub" title="Fork me on GitHub">Fork me on GitHub</a>
      <div style={{
        display: 'flex',
        justifyContent:'center',
        alignItems:'center',
        height: '100vh',
        background: '#151515',
        flexDirection: 'column',
      }}>
        <img style={{width: '40%', }} src={logo} alt='Blackorder'/>
        <div>
          <div style={{fontWeight: 700, fontSize: 22, color: '#c7c7c7', textAlign: 'center'}}>
            <Link href="https://github.com/BlackOrderMod" title='Our repositories!' text='GITHUB'/> |{' '}
            <Link href="https://discord.gg/RNYptZZeZ4" title='Join the order!' text='DISCORD'/> |{' '}
            <Link href="https://shop.spreadshirt.se/1091591/" title='Join the cult!' text='MERCH STORE'/> |{' '}
            <Link href="https://ko-fi.com/evuldev/" title='Give the main developer a coffie!' text='KO-FI'/>
          </div>
          <Pusher size={12}/>
          <div style={{background: '#c7c7c7', height: 2, width: '100%' }}/>
        </div>

        <Pusher size={60}/>
          <div style={{textAlign: 'center' }}>
            <div>
              <p style={{color: '#c7c7c7'}}>
                Welcome to the Black Order Mods offical webpage. The <b>Black Order</b> or <b>Terrorist Organization Black Order</b> are a mod devleloped for ARMA 3.
              </p>
            </div>
          </div>
      </div>
    </div>
  );
}

export default App;
